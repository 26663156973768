import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import web from './web.gif'
import { TiArrowBackOutline } from "react-icons/ti";
import { Grid } from '@mui/material';
function AboutDesktop() {
  const paragraphsRef = useRef([]);

  useEffect(() => {
    const paragraphs = paragraphsRef.current;
    const timeline = gsap.timeline(); // Create a GSAP timeline

    paragraphs.forEach((p, index) => {
      // Split the text into an array of characters
      const chars = p.innerText.split('');
      p.innerHTML = ''; // Clear the inner text

      // Create a span for each character and append to the paragraph
      chars.forEach((char) => {
        const span = document.createElement('span');
        span.innerText = char;
        p.appendChild(span);
      });

      // Add each paragraph's animation to the timeline, one after the other
      timeline.fromTo(
        p.querySelectorAll('span'),
        { opacity: 0, y: 50 }, // Start with characters invisible and slightly below
        {
          opacity: 1,
          y: 0,
          ease: 'power2.out',
          duration: 0.05,
          stagger: 0.02, // Delay between each character
        },
        index === 0 ? 0 : "+=0.5" // First line starts immediately, next lines start after the previous one ends
      );
    });
  }, []);

  return (
    <div className='container'>
      <Grid container>
        <Grid item md={3} lg={3} xs={3} sm={3}>
       
        <a href='/Home'>
         
        <div className='about_containerback'>
      
      <div className='about-buttonback'>
        <span className='text'>
<TiArrowBackOutline/>

        </span>
      </div>
    </div>
        </a>
         
      
      </Grid>
      <Grid item md={6} lg={6} xs={6} sm={6}>
       
      <div className='about_container'>
        <div className='about-button'>
          <span className='text'>ABOUT</span>
        </div>
      </div>
     
     </Grid>
     

     <Grid item md={3} lg={3} xs={3} sm={3}>
       
        <>
         
        <div className='enditall'>
      <img src={web} style={{width:"200px"}} />
      
    </div>
        </>
         
      
      </Grid>
      </Grid>


    

      <div className="containera">
      <p ref={(el) => (paragraphsRef.current[0] = el)} className='pafk' style={{textAlign:"center"}}>
       Founder
        </p>
        <p ref={(el) => (paragraphsRef.current[1] = el)} className='paf'>
        Our team has been active in the Ordinal space since the early Sub10k days, from etching one-of-one interactive and GIF inscriptions to assisting in running mass nodes for others in the space during the early OTC days, alongside EarlyOrDie and VeryOrdinally. Additionally, we founded the Sub11.1k OrdTrippyPunks collection. Our crypto involvement dates back to being selected as creators for the RTFKT team in 2021 and being included in the Forbes Web3 Inner Circle Legacy Club.
        </p>
        <p ref={(el) => (paragraphsRef.current[2] = el)} className='pafk' style={{textAlign:"center"}}>
        The Artists
        </p>

        <p ref={(el) => (paragraphsRef.current[3] = el)} className='paf'>
        The artists for this collection include our good friend Antoine Mingo, the lead artist for Pudgy Penguins, alongside Indonesian street artist Korou. 
        </p>
        <p ref={(el) => (paragraphsRef.current[4] = el)} className='pafk' style={{textAlign:"center"}}>
        Got Art?
        </p>

        <p ref={(el) => (paragraphsRef.current[5] = el)} className='paf'>
        We’ve more than doubled the number of traits found in a typical NFT or Ordinal collection, with every element precisely stored on-chain. Each inscription is crafted to be a one-of-a-kind creation. After countless late nights, relentless refinement, and boundless creativity, the phrase "Art on Bitcoin" isn't just a tagline—it’s the very code that runs through every masterpiece.

        </p>
        <p ref={(el) => (paragraphsRef.current[6] = el)} className='pafk' style={{textAlign:"center"}}>
        Backend Protocol
        </p>

        <p ref={(el) => (paragraphsRef.current[7] = el)} className='paf'>
        Prioritizing low fees and high-quality art, Jeets On Bitcoin uses a recursive ordinal mechanism. Our team values provenance and origin, so the collection incorporates parent-child inscriptions that point back to our early involvement in Ordinals.

        </p>
        <p ref={(el) => (paragraphsRef.current[8] = el)} className='pafk' style={{textAlign:"center"}}>
        Tokenized-Enabled Membership
        </p>

        <p ref={(el) => (paragraphsRef.current[9] = el)} className='paf'>
        We’ve also partnered with Uncheckable, where the most exclusive alpha is shared. This inscription not only serves as a collector’s art piece but also as one of the first on-chain memberships. This membership functions as an all-in-one pass for Web3 entrepreneurs and enthusiasts, covering everything from blockchain insights, Web3 opportunities, and a profitable reselling hub maximum earning potential. Uncheckable gives holders access to the most insightful and cutting-edge alpha.
        </p>
        <span ref={(el) => (paragraphsRef.current[10] = el)} className='paf'>
  Learn more :  
  
</span>
&nbsp;         <a href='https://uncheckable.us/' target='_blank' rel='noopener noreferrer' ref={(el) => (paragraphsRef.current[11] = el)} className='paf'>
             https://uncheckable.us/
                     </a>


          
      </div>

    </div>
  );
}

export default AboutDesktop;
