import React, { useState } from "react";
import musicFile from "./creep.mp3";
import { Modal } from "react-bootstrap";
import { useMediaQuery } from "@mui/material";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

const Home = () => {
  const [showModal, setShowModal] = useState(true);
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleYesClick = () => {

    // Set timeout to hide the modal after 2 seconds
    setTimeout(() => {
      setShowModal(false);
    }, 500); // 2000 milliseconds = 2 seconds
  };

  return (
    <div className="mainContent">
      <div className="garden">
        {!smallScreen && (
          <div className="Homepage">
            
            <Desktop />
            
          </div>
        )}
        {smallScreen && (
          <div className="Homepagemob">
             <Mobile />
          </div>
        )}
      </div>

      <>
        <Modal
          backdrop="static"
          show={showModal}
          keyboard={false}
          onHide={() => {}}
          className=""
        >
          <div className="sunken-panel w-full ">
            <br />
            <h1 className="text-center doyou">Acknowledgment of Conviction</h1>
            <br />
            <div className="container">
              <p className="text-center typing-demo">
                By entering this website, I acknowledge the conviction associated with jeeting and understand that it is not to be taken lightly.
              </p>
            </div>

            <div className="centerall">
              <div className="checkbox-container">
                <label>
                  <input
                    type="checkbox"
                    className="glowing-checkbox"
                    onChange={handleYesClick} // Trigger the delayed modal close
                  />
                  <span className="checkbox-text">
                    If you agree, please check this box to access the site.
                  </span>
                </label>
              </div>
            </div>
            <br />
          </div>
        </Modal>
      </>
    </div>
  );
};

export default Home;
