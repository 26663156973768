import React from 'react'
import { Grid } from '@mui/material'
import WadesETH from './HONORARIES/Wades ETH.png'
import Langeriuseth from './HONORARIES/Langeriuseth.png'
import barbra7 from './HONORARIES/barbra7.png'
import GoodThings from './HONORARIES/GoodThings.png'
import pazNGMI from './HONORARIES/pazNGMI.png'
import shin_raton from './HONORARIES/shin_raton.png'
import lifeiscrazynft from './HONORARIES/lifeiscrazynft.png'
import veryordinally from './HONORARIES/veryordinally.png'
import Yeezy from './HONORARIES/Yeezy.png'
import Pfourma from './HONORARIES/Pfourma.png'
import Nikola from './HONORARIES/Nikola.png'
import TahaAhmed from './HONORARIES/Taha Ahmed.png'
import HammadBTC from './HONORARIES/HammadBTC.png'
import ZachKag from './HONORARIES/Zach Kag.png'
import NFTBoi from './HONORARIES/NFT Boi.png'
import SugarGirlSats from './HONORARIES/SugarGirl Sats.png'
import MEonBTC from './HONORARIES/MEonBTC.png'
import RektMando from './HONORARIES/RektMando.png'
import Resaang from './HONORARIES/Resaang.png'
import NftSistersJapan from './HONORARIES/NftSistersJapan.png'
import Redacted7777 from './HONORARIES/Redacted7777.png'
import LUCIDBTC from './HONORARIES/LUCIDBTC.png'
import LifoFifo from './HONORARIES/LifoFifo.png'
import T4T5 from './HONORARIES/T4T5.png'
import KevinSusanto from './HONORARIES/Kevin Susanto.png'
import RaphJaph from './HONORARIES/Raph Japh.png'
import Dazza9x from './HONORARIES/Dazza9x.png'
import DonaldTrump from './HONORARIES/Donald Trump.png'
import KaliCrypto from './HONORARIES/Kali Crypto.png'
import Ssheyii from './HONORARIES/Ssheyii.png'
import NaqibNoor from './HONORARIES/Naqib Noor.png'
import FulltiltFrancis from './HONORARIES/FulltiltFrancis.png'
import Ordzaar from './HONORARIES/Ordzaar.png'
import TaprootAlpha from './HONORARIES/Taproot Alpha.png'
import BluToshi from './HONORARIES/BluToshi.png'
import MrAnchovy from './HONORARIES/Mr Anchovy.png'
import TheRoaringKitty from './HONORARIES/The Roaring Kitty.png'
import OMB from './HONORARIES/OMB.png'
import BasedAngelsBTC from './HONORARIES/Based Angels BTC.png'
import FrucksBTC from './HONORARIES/FrucksBTC.png'
import InscribeNow from './HONORARIES/Inscribe Now.png'
import TheGreatOla from './HONORARIES/TheGreatOla.png'
import PGgroup from './HONORARIES/download.gif'

import { TiArrowBackOutline } from "react-icons/ti";





function AboutDesktop() {
  return (
    <div className='container'>
        <Grid container>
        <Grid item md={3} lg={3} xs={3} sm={3}><br/>   <br/>
        <a href='/Home'>
         
        <div className='about_containerback'>
      
      <div className='about-buttonback'>
        <span className='text'>
<TiArrowBackOutline/>

        </span>
      </div>
    </div>
        </a>
         
      
      </Grid>
      </Grid>
      <br/>
<div className='about_container mainfont'> <div class="about-button mainfont">
        <span class="text mainfont">HONORARIES</span>
    </div></div>
<br/>

<Grid container spacing={2}>
<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/Wades_ETH' target='"_blank'>
<img src={WadesETH} style={{width:"100%" , border:"2px solid #000"}}/>
</a>

</Grid>


<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/langeriuseth' target='"_blank'>
<img src={Langeriuseth} style={{width:"100%" , border:"2px solid #000"}}/>
</a>

</Grid>

<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/thatwagmigirl' target='"_blank'>
<img src={barbra7} style={{width:"100%" , border:"2px solid #000"}}/>
</a>

</Grid>

<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/Goodthings' target='"_blank'>
<img src={GoodThings} style={{width:"100%" , border:"2px solid #000"}}/>
</a>

</Grid>

<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/pazNGMI' target='"_blank'>
<img src={pazNGMI} style={{width:"100%" , border:"2px solid #000"}}/>
</a>

</Grid>

<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/shin_raton' target='"_blank'>
<img src={shin_raton} style={{width:"100%" , border:"2px solid #000"}}/>
</a>

</Grid>

<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/lifeiscrazynft' target='"_blank'>
<img src={lifeiscrazynft} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>

<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/veryordinally' target='"_blank'>
<img src={veryordinally} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>



<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/kanyewest' target='"_blank'>
<img src={Yeezy} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>


<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/pfourma' target='"_blank'>
<img src={Pfourma} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>

<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/Nikola_kingo' target='"_blank'>
<img src={Nikola} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>

<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/tahaahmed' target='"_blank'>
<img src={TahaAhmed} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>

<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/Hammadbtc' target='"_blank'>
<img src={HammadBTC} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>

<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/Zachkag' target='"_blank'>
<img src={ZachKag} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>


<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://twitter.com/nftboi_?s=21' target='"_blank'>
<img src={NFTBoi} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>

<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/The_sugargirl' target='"_blank'>
<img src={SugarGirlSats} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>

<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/MEonBTC' target='"_blank'>
<img src={MEonBTC} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>

<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/rektmando' target='"_blank'>
<img src={RektMando} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>

<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/resaang' target='"_blank'>
<img src={Resaang} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>


<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/NFTsistersJAPAN' target='"_blank'>
<img src={NftSistersJapan} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>


<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/redacted7777' target='"_blank'>
<img src={Redacted7777} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>


<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/lucidbtc' target='"_blank'>
<img src={LUCIDBTC} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>


<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/lifofifo' target='"_blank'>
<img src={LifoFifo} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>



<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/t4t5' target='"_blank'>
<img src={T4T5} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>



<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/KevinSusanto' target='"_blank'>
<img src={KevinSusanto} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>


<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/raphjaph?s=21' target='"_blank'>
<img src={RaphJaph} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>




<Grid item lg={3} md={3} sm={6} xs={6}>

<a href='https://x.com/dazza9x?s=21' target='"_blank'>
<img src={Dazza9x} style={{width:"100%" , border:"2px solid #000"}}/>
</a>



</Grid>


<Grid item lg={3} md={3} sm={6} xs={6}>
<a href='https://x.com/realDonaldTrump' target='"_blank'>
<img src={DonaldTrump} style={{width:"100%" , border:"2px solid #000"}}/>
</a>
</Grid>


<Grid item lg={3} md={3} sm={6} xs={6}>
<a href='https://x.com/kalicryptoo' target='"_blank'>
<img src={KaliCrypto} style={{width:"100%" , border:"2px solid #000"}}/>
</a>
</Grid>


<Grid item lg={3} md={3} sm={6} xs={6}>
<a href='https://x.com/ssheyii' target='"_blank'>
<img src={Ssheyii} style={{width:"100%" , border:"2px solid #000"}}/>
</a>
</Grid>



<Grid item lg={3} md={3} sm={6} xs={6}>
<a href='https://x.com/Naqib_Noor' target='"_blank'>
<img src={NaqibNoor} style={{width:"100%" , border:"2px solid #000"}}/>
</a>
</Grid>


<Grid item lg={3} md={3} sm={6} xs={6}>
<a href='https://x.com/FullTiltFrancis' target='"_blank'>
<img src={FulltiltFrancis} style={{width:"100%" , border:"2px solid #000"}}/>
</a>
</Grid>


<Grid item lg={3} md={3} sm={6} xs={6}>
<a href='https://x.com/ordzaar' target='"_blank'>
<img src={Ordzaar} style={{width:"100%" , border:"2px solid #000"}}/>
</a>
</Grid>


<Grid item lg={3} md={3} sm={6} xs={6}>
<a href='https://x.com/taprootalpha' target='"_blank'>
<img src={TaprootAlpha} style={{width:"100%" , border:"2px solid #000"}}/>
</a>
</Grid>


<Grid item lg={3} md={3} sm={6} xs={6}>
<a href='https://x.com/Blutoshi' target='"_blank'>
<img src={BluToshi} style={{width:"100%" , border:"2px solid #000"}}/>
</a>
</Grid>





<Grid item lg={3} md={3} sm={6} xs={6}>
<a href='https://x.com/Mr_Anchovy_' target='"_blank'>
<img src={MrAnchovy} style={{width:"100%" , border:"2px solid #000"}}/>
</a>
</Grid>


<Grid item lg={3} md={3} sm={6} xs={6}>
<a href='https://x.com/TheRoaringKitty' target='"_blank'>
<img src={TheRoaringKitty} style={{width:"100%" , border:"2px solid #000"}}/>
</a>
</Grid>



<Grid item lg={3} md={3} sm={6} xs={6}>
<a href='https://x.com/OrdinalMaxiBiz' target='"_blank'>
<img src={OMB} style={{width:"100%" , border:"2px solid #000"}}/>
</a>
</Grid>

<Grid item lg={3} md={3} sm={6} xs={6}>
<a href='https://x.com/basedangelsbtc' target='"_blank'>
<img src={BasedAngelsBTC} style={{width:"100%" , border:"2px solid #000"}}/>
</a>
</Grid>


<Grid item lg={3} md={3} sm={6} xs={6}>
<a href='https://x.com/frucksBTC' target='"_blank'>
<img src={FrucksBTC} style={{width:"100%" , border:"2px solid #000"}}/>
</a>
</Grid>




<Grid item lg={3} md={3} sm={6} xs={6}>
<a href='https://x.com/inscribenow_io' target='"_blank'>
<img src={InscribeNow} style={{width:"100%" , border:"2px solid #000"}}/>
</a>
</Grid>


<Grid item lg={3} md={3} sm={6} xs={6}>
<a href='https://x.com/thegreatola' target='"_blank'>
<img src={TheGreatOla} style={{width:"100%" , border:"2px solid #000"}}/>
</a>
</Grid>


<Grid item lg={3} md={3} sm={6} xs={6}>
<a href='https://x.com/PGgroup_' target='"_blank'>
<img src={PGgroup} style={{width:"100%" , border:"4px solid #000"}}/>
</a>
</Grid>




</Grid>



    </div>
  )
}

export default AboutDesktop