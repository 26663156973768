import React, { useState } from "react";
import musicFile from "./creep.mp3";
import { Modal } from "react-bootstrap";
import { useMediaQuery } from "@mui/material";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

const Home = () => {
  const [showModal, setShowModal] = useState(true);
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleYesClick = () => {

    // Set timeout to hide the modal after 2 seconds
    setTimeout(() => {
      setShowModal(false);
    }, 500); // 2000 milliseconds = 2 seconds
  };

  return (
    <div className="mainContent">
      <div className="garden">
        {!smallScreen && (
          <div className="Homepage">
            
            <Desktop />
            
          </div>
        )}
        {smallScreen && (
          <div className="Homepagemob">
             <Mobile />
          </div>
        )}
      </div>

    </div>
  );
};

export default Home;
