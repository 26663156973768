import React, { useState } from "react";
import musicFile from "./creep.mp3";
import { Modal } from "react-bootstrap";
import { useMediaQuery } from "@mui/material";

import HONORARIESDesktop from "./HONORARIESDesktop";
import HONORARIESMobile from "./HONOMOBILE";

const Home = () => {
  const [showModal, setShowModal] = useState(true);
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleYesClick = () => {
    // Code to play music
    const audio = new Audio(musicFile);
    audio.loop = true;
    audio.play();

    // Set timeout to hide the modal after 2 seconds
    setTimeout(() => {
      setShowModal(false);
    }, 2000); // 2000 milliseconds = 2 seconds
  };

  return (
    <div className="mainContent">
      <div className="garden">
        {!smallScreen && (
          <div className="HomepageAbouth">
          <div class="BlurBackgroundh"></div>
          <div class="GlassOverlayh">
            
            <HONORARIESDesktop />
            
          </div> </div>
        )}
        {smallScreen && (
           <div className="HomepageAboutha">
           <div class="BlurBackgroundha"></div>
           <div class="GlassOverlayha">
             <HONORARIESMobile />
          </div></div>
        )}
      </div>

      <>
     
      </>
    </div>
  );
};

export default Home;
