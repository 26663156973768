import React from 'react';
import { Grid } from '@mui/material';
import { FaXTwitter } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa";
function Desktop() {
  return (
    <div className='container' >
      <Grid container spacing={1} className="">
      <Grid item md={8} xs={8} sm={8} lg={8} >
   
    

   </Grid>
      <Grid item md={4} xs={4} sm={4} lg={4} style={{position:"relative", top:"7px" , right:"20px" }}>
      <button className="styled-buttonm12  mainfont" >Home</button>

      <div> 
      <a href='/About' style={{position:"relative", top:"9px"}}>
      <button className="styled-buttonm12  mainfont">About</button>
      </a>
      </div>
      <div style={{position:"relative", top:"9px"}}> 
      <a href='/HONORARIES' style={{position:"relative", top:"9px"}}>
      <button className="styled-buttonm123  mainfont">
        
        Honoraries
        
        
        </button>
      </a>
      </div>
     
      <div style={{position:"relative", top:"17px"}}> 
      <a href='https://x.com/jeetsonbtc?lang=en' style={{position:"relative", top:"9px"}}>
      <button className="styled-buttonm1234  ">
      <FaXTwitter className="x-iconx " />
        
        </button>
      </a>
      </div>

<div>


</div>

</Grid>




      </Grid>
     
    
    
    </div>
  );
}

export default Desktop;
