import React, { useState, useEffect } from 'react';
import { ThemeProvider } from "@mui/material/styles";
import { Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import About from "./Components/About";
import HONORARIES from './Components/HONORARIES'
import HomeFinal from './Components/HomeFinal'
import theme from "./Theme";
import { Grid } from '@mui/material';

import "./lines.scss";

function LoadingScreen() {
  return (
    <div className="loading-screen">
      <Grid container>
        <br/>     <br/>
         
          </Grid>
      {/* You can add additional loading indicators here */}
    </div>
  );
}

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulating a delay for loading (you can replace this with your actual loading logic)
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 100); // 2000 milliseconds (2 seconds) for demonstration purposes

    return () => clearTimeout(loadingTimeout);
  }, []);

  return (
    <div className="cursor">
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <ThemeProvider theme={theme}>
          <Routes>
            <Route
              path="/"
              element={<Home />}
            />
              <Route
              path="/Home"
              element={<HomeFinal />}
            />
             <Route
              path="/About"
              element={<About />}
            />
            <Route
              path="/HONORARIES"
              element={<HONORARIES />}
            />


          </Routes>
        </ThemeProvider>
      )}
    </div>
  );
}

export default App;
