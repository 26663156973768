import React from 'react';
import { Grid } from '@mui/material';
import { FaXTwitter } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa";
function Desktop() {
  return (
    <div className='container' >
      <Grid container spacing={1} className="button-container">
      <Grid item md={9}  lg={9}>

      </Grid>
      <Grid item md={3}  lg={3}  style={{position:"relative", top:"7px"}}>
      <button className="styled-button mainfont">Home</button>
<br/>
      <a href='/About' style={{position:"relative", top:"7px"}}><button className="styled-button mainfont">About</button></a>
      <br/>
      <a href='/HONORARIES' style={{position:"relative", top:"13px" , left:"40px"}}>
  <button className="styled-buttont mainfont">Honoraries</button>
  </a>
  <br/>


  <a href='https://x.com/jeetsonbtc?lang=en' style={{position:"relative", top:"4px"}}>
    <button className="x-button">
      <FaXTwitter className="x-icon " />
    </button></a>
</Grid>


      </Grid>
     
    
    
    </div>
  );
}

export default Desktop;
